import { createTheme, ThemeOptions } from '@mui/material/styles';
import { lightBlue } from '@mui/material/colors';

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#108fe8',
    },
    secondary: {
      main: '#ffcb17',
    },
  },
};
export const theme = createTheme({
  palette: {
    primary: {
      main: '#108fe8',
    },
    secondary: {
      main: '#ffcb17',
    },
  },
});
